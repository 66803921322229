<template>
  <div style="width: 47%">
    <div v-if="!imageUrl" class="w-full">
      <p class="text-jet font-semibold mb-3 text-lg">{{ type }}</p>
      <div
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="handleDropFiles"
        class="w-full bg-others flex flex-col justify-center items-center gap-3 py-3 rounded-md"
      >
        <icon icon-name="storage" size="s" />
        <p
          class="w-full mx-auto text-center flex flex-col justify-center items-center"
        >
          <span>Drag and drop files here</span>
          <span>OR</span>
        </p>
        <Button
          :background="btnBackgroundColor"
          @click="triggerInputFile"
          class="text-white flex flex-col justify-center items-center w-28 h-9"
        >
          Browse Files
        </Button>
        <input
          type="file"
          @change="handleInputFiles"
          ref="fileInput"
          accept="image/*"
          hidden
        />
        <p class="text-romanSilver text-xs">*Upload a 400 x 200px {{ type }}</p>
      </div>
    </div>

    <div
      v-if="imageUrl"
      class="w-full h-auto py-3 flex flex-col justify-center items-center gap-3"
    >
      <div class="w-full h-52">
        <img
          :src="imageUrl"
          :alt="`${type} image Uploaded`"
          class="w-full h-full"
        />
      </div>

      <p class="w-full text-sm text-blueYonder text-right font-bold">
        <span @click="triggerInputFile" class="cursor-pointer text-desire"
          >Upload</span
        >
        |
        <span @click="removeUploadedimage" class="cursor-pointer"
          >Remove {{ type }}</span
        >
      </p>
      <input
        type="file"
        @change="handleInputFiles"
        ref="fileInput"
        accept="image/*"
        hidden
      />
    </div>

    <div
      v-if="uploading"
      class="w-full pt-3 h-auto flex flex-col justify-center items-center gap-3"
    >
      <div class="line" />

      <div
        class="w-full h-fit rounded-lg px-4 py-2 flex justify-between items-center gap-6 b-line"
      >
        <div class="h-11 flex gap-3">
          <div v-if="tempImageUrl" class="w-8 h-full">
            <img
              :src="tempImageUrl"
              :alt="`Uploading ${fileName}`"
              class="w-full h-full"
            />
          </div>
          <div class="uploading-text flex flex-col justify-center items-start">
            <p class="text-blackJet font-bold py-0 my-0">
              {{ fileName }} IMG {{ fileType }}
            </p>

            <progress
              :value="uploadPercentage"
              v-if="!networkError"
              max="100"
              :style="{ backgroundColor: progressBarColor }"
              class="w-11/12 h-1 border-none rounded-3xl my-0"
            ></progress>

            <p class="py-0 my-0">
              <span v-if="networkError" class="text-desire">
                Upload Interrupted
              </span>
              <span v-if="!networkError" class="text-blueCrayola">
                Uploading... {{ uploadPercentage }}%
              </span>
              <span class="text-romanSilver font-bold">
                • {{ remainingSize.toFixed(2) }}MB of
                {{ imageSizeMB.toFixed(2) }} MB
              </span>
            </p>
          </div>
        </div>

        <div class="flex gap-3">
          <span v-if="networkError" @click="retryUpload" class="cursor-pointer">
            <icon icon-name="icon-undo" size="s" />
          </span>
          <span @click="cancelUpload" class="cursor-pointer">
            <icon icon-name="icon-close" size="s" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { lazyLoadInternalComp } from "@/utilities/lazyLoadComponent";

export default {
  props: {
    type: String,
    imageUrl: String || null,
    btnBackgroundColor: String,
    endPoint: String,
  },
  components: {
    Button: lazyLoadInternalComp("Button"),
  },
  data() {
    return {
      uploading: false,
      imageSizeMB: 0,
      remainingSize: 0,
      networkError: false,
      tempImageUrl: null,
      fileName: "",
      fileType: "",
      file: null,
      uploadPercentage: 0,
      cancelTokenSource: null,
    };
  },

  computed: {
    progressBarColor() {
      // Start color components
      const startR = 33;
      const startG = 118;
      const startB = 255;

      // End color components
      const endR = 135;
      const endG = 142;
      const endB = 153;

      // Current progress color components
      const currentR = Math.round(
        startR + (endR - startR) * (this.uploadPercentage / 100)
      );
      const currentG = Math.round(
        startG + (endG - startG) * (this.uploadPercentage / 100)
      );
      const currentB = Math.round(
        startB + (endB - startB) * (this.uploadPercentage / 100)
      );

      return `rgb(${currentR}, ${currentG}, ${currentB})`;
    },
  },
  methods: {
    getImageUrl(imageUrl) {
      this.$emit("update-appearance", imageUrl, this.type);
    },

    async triggerInputFile() {
      if (this.type === "Banner") {
        try {
          await this.$handlePrivilege("appearanceSetting", "uploadBanner");
          this.$refs.fileInput.click();
        } catch (error) {
          return this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      } else {
        try {
          await this.$handlePrivilege("appearanceSetting", "uploadLogo");
          this.$refs.fileInput.click();
        } catch (error) {
          return this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      }
    },

    dragOver(e) {
      e.target.style.background = "#eee";
    },

    dragLeave(e) {
      e.target.style.background = "#F6F8F9";
    },

    handleDropFiles(e) {
      const files = e.dataTransfer.files;
      if (files.length > 0) {
        this.prepareFileUpload(files[0]);
      } else {
        return this.$toasted.error(
          "Direct image drop from external sites is restricted by browser security.",
          {
            duration: 6000,
          }
        );
      }
    },

    handleInputFiles() {
      const files = this.$refs.fileInput.files;
      this.prepareFileUpload(files[0]);
    },

    prepareFileUpload(file) {
      if (!file) return;

      this.reset();
      let name = file.name;
      this.fileName = name.substr(0, name.lastIndexOf("."));
      this.fileType = file.type.split("/").pop().toLowerCase(); // Extract and format file type
      this.file = file;
      this.tempImageUrl = URL.createObjectURL(file);

      this.validateAndUploadFile(file);
    },

    validateAndUploadFile(file) {
      const img = new Image();
      const url = URL.createObjectURL(file);
      img.src = url;

      // Defined onload handler, executed once the image has fully loaded
      img.onload = () => {
        const { width, height } = img;
        if (width > 400 || height > 200) {
          URL.revokeObjectURL(url); // Cleaned up object URL
          return this.$toasted.error("Image dimensions exceed 400 x 200px.", {
            duration: 6000,
          });
        } else {
          this.uploadImage(file);
        }
      };

      // Defined onerror handler, in case the file is not a valid image
      img.onerror = () => {
        URL.revokeObjectURL(url); // Cleaned up object URL
        return this.$toasted.error("Invalid image file.", {
          duration: 6000,
        });
      };
    },

    async uploadImage() {
      this.uploading = true;
      const totalSize = this.file.size / 1024 / 1024;
      const formData = new FormData();
      formData.append("file", this.file);

      // Creating a new CancelToken
      this.cancelTokenSource = axios.CancelToken.source();

      try {
        const { data } = await axios.post(this.endPoint, formData, {
          cancelToken: this.cancelTokenSource.token,
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            this.imageSizeMB = progressEvent.loaded / 1024 / 1024; // convert to MB
            this.remainingSize = totalSize - this.imageSizeMB;
          },
        });
        this.uploading = false;
        this.getImageUrl(data.fileUrl);
      } catch (error) {
        if (axios.isCancel(error)) {
          return this.$toasted.success(`Request canceled, ${error.message}`, {
            duration: 6000,
          });
        } else {
          return (this.networkError = true);
        }
      }
    },

    cancelUpload() {
      console.log("cancel Updaa");
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Upload successfully canceled.");
        this.reset();
      }
    },

      async removeUploadedimage() {
          if (this.type === 'Banner') {
              try {
                  await this.$handlePrivilege("appearanceSetting", "removeBanner");
                  this.getImageUrl(null);
                  this.reset();
                  this.file = null;
              } catch (error) {
                  return this.$toasted.error(
                      "You do not have permission to perform this task",
                      {
                          duration: 5000,
                      }
                  );
              }
          }
          else {
              try {
                  await this.$handlePrivilege("appearanceSetting", "removeLogo");
                  this.getImageUrl(null);
                  this.reset();
                  this.file = null;
              } catch (error) {
                  return this.$toasted.error(
                      "You do not have permission to perform this task",
                      {
                          duration: 5000,
                      }
                  );
              }
          }
    },

    retryUpload() {
      if (this.file) {
        this.networkError = false;
        this.prepareFileUpload(this.file);
      }
    },

    reset() {
      this.uploading = false;
      this.imageSizeMB = 0;
      this.remainingSize = 0;
      this.networkError = false;
      this.fileName = "";
      this.fileType = "";
      this.tempImageUrl = null;
      this.uploadPercentage;
      this.cancelTokenSource = null;
    },
  },
};
</script>

<style scoped>
.line {
  border: 0.5px solid #d6dbe4;
  width: 85%;
  height: 0;
  margin: auto;
  padding: 0;
}

.b-line {
  border: 0.6px solid #c2c7d6;
}

.uploading-text {
  font-size: 10px;
  gap: 1px;
}
</style>
